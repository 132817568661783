.app__highlights {
    flex: 1;
    width: 100%;
    flex-direction: column;
}
iframe {
    margin-top: 5rem;
    width: 60rem;
    height: 33rem;

    @media screen and (max-width: 850px) {
        width: 100%;
        height: 15rem;
    }
}
