#home {
    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 90%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    @media screen and (max-width: 850px) {
        display: flex;
        width: auto;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    h1 {
        font-size: 3rem;
        width: 100%;
        text-align: center;
        @media screen and (min-width: 2000px) {
            font-size: 7rem;
        }

        @media screen and (max-width: 850px) {
            font-size: 2.5rem;
        }
    }
}
.app__header-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-end: span 3;
    height: 100%;
}
.app__header-img {
    border-radius: 50%;
    border: 3px solid var(--secondary-color);
    box-shadow: 10px 10px 20px black;
    @media screen and (max-width: 850px) {
        width: 17.5rem;
    }
}
.app__header-icon {
    @media screen and (max-width: 850px) {
        display: none;
    }
}
